<template>
  <div class="col-md-12">
    <div class="rp-card rp-card-prev mb-4">
      <div class="row">
        <div class="col-12">
          <h5 class="font-weight-bolder mb-0">
            Preventivo Camera {{ index + 1 }}
          </h5>
          <p class="small text-secondary mb-3">nr. #{{ request.id }}</p>
          <hr class="mt-lg-3" />
          <ul class="fa-ul">
            <li class="mb-2">
              <span class="fa-li"
                ><font-awesome-icon
                  :icon="['fas', 'calendar-alt']"
                  class="fa-fw, mt-1, mr-2" /></span
              >Arrivo {{ request.arrival | formatDate }} - Partenza
              {{ request.departure | formatDate }}
            </li>
            <li class="mb-2">
              <span class="fa-li">
                <font-awesome-icon
                  :icon="['fas', 'user-friends']"
                  class="fa-fw, mt-1, mr-2"
                /> </span
              >Numero Adulti: {{ quote.adults }} - Numero Bambini:
              {{ quote.childs }}
            </li>
            <li class="mb-2">
              <span class="fa-li"
                ><font-awesome-icon
                  :icon="['fas', 'concierge-bell']"
                  class="fa-fw, mt-1, mr-2" /></span
              >{{ quote.treatment }}
            </li>
            <li>
              <span class="fa-li"
                ><font-awesome-icon
                  :icon="['fas', 'file-invoice']"
                  class="fa-fw, mt-1, mr-2"
              /></span>
              Tassa di soggiorno non inclusa.
            </li>
          </ul>
          <hr />
        </div>
        <room-quote-box
          v-for="(room, room_quote_index) in quote.rooms"
          :key="room_quote_index"
          :quote_index="index"
          :index="room_quote_index"
          :room="room"
          :confirmed_blocks="confirmed_blocks"
        />
        <div class="col-12" v-show="terms_and_condition_text">
          <div class="small" v-html="terms_and_condition_text">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomQuoteBox from "./RoomQuoteBox";

export default {
  name: "QuoteBox",
  props: ["index", "quote", "request", "confirmed_blocks", "terms_and_condition_text"],
  components: {
    RoomQuoteBox,
  },
};
</script>