<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="overlay" v-show="loading">
          <div class="overlay__inner">
            <div class="overlay__content text-center">
              <span class="spinner"></span>
              <div class="d-block">
                <h5 class="mt-3 mb-0 text-dark">
                  Calcolo il tuo preventivo,<br />solo pochi secondi ...
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="rp-card">
          <b-form @submit.stop.prevent="submit">
            <div class="form-row">
              <div class="col-12 form-margin">
                <h4 class="text-center mb-4">Calcola il tuo preventivo!</h4>
                <date-picker v-model="range" is-range is-required :min-date="today">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="flex justify-center items-center">
                      <div class="w-calendar-input d-inline-block">
                        <label>Arrivo</label>
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="form-control"
                        />
                      </div>
                      <svg
                        class="w-calendar-ico mx-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        />
                      </svg>
                      <div class="w-calendar-input d-inline-block">
                        <label>Partenza</label>
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </template>
                </date-picker>
              </div>
              <div class="col-12 form-margin">
                <label>Quante camere?</label>
                <b-form-spinbutton
                  id="rooms"
                  min="1"
                  max="5"
                  class="pr-0 pl-0"
                  :value="1"
                  @input="addOrRemoveRoom"
                ></b-form-spinbutton>
              </div>
              <room
                v-for="(room, index) in request.rooms"
                :key="index"
                :index="index"
                :room="room"
                :v="$v.request.rooms.$each[index]"
              />
              <!-- REFERENTE -->
              <div class="col-12">
                <hr class="mt-1" />
                <h5 class="font-weight-bolder mb-3">
                  <font-awesome-icon :icon="['fas', 'user']" class="mr-2" />Dati cliente
                </h5>
              </div>
              <div class="col-12 form-margin">
                <label>Nome e Cognome</label>
                <b-form-input
                  id="name"
                  v-model="$v.request.name.$model"
                  type="text"
                  placeholder="Mario Rossi"
                  :state="validateState('name')"
                  aria-describedby="name-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="name-feedback"
                  >Questo campo non può essere vuoto</b-form-invalid-feedback
                >
              </div>
              <div class="col-12 form-margin">
                <label>E-mail</label>
                <b-form-input
                  v-model="$v.request.email.$model"
                  type="email"
                  placeholder="mariorossi@ ..."
                  :state="validateState('email')"
                  aria-describedby="email-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="email-feedback"
                  >Questo campo non può essere vuoto</b-form-invalid-feedback
                >
              </div>
              <div class="col-12">
                <b-form-checkbox
                  id="privacy"
                  name="privacy"
                  v-model="$v.request.privacy.$model"
                  :state="validateState('privacy')"
                  aria-describedby="privacy-feedback"
                  >Ho letto la policy per il
                  <u
                    ><a :href="privacy_url" target="blank" class="underline"
                      >trattamento dei dati</a
                    ></u
                  >
                </b-form-checkbox>
                <b-form-invalid-feedback
                  id="privacy-feedback"
                  class="d-block"
                  v-if="$v.request.privacy.$error"
                  >E' necessario accettare le condizioni sulla privacy per
                  continuare</b-form-invalid-feedback
                >
                <div class="text-center mt-4">
                  <b-button
                    type="submit"
                    variant="action"
                    class="btn-info"
                    :disabled="loading"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'calculator']"
                      class="mr-2"
                    />Calcola il preventivo</b-button
                  >
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minValue, email, not, sameAs } from "vuelidate/lib/validators";
import Room from "../components/Room";
import moment from "moment";

export default {
  name: "Home",
  components: {
    Room,
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date().setDate(new Date().getDate() + 1),
      },
      request: {
        name: "",
        email: "",
        privacy: false,
        rooms: [
          {
            adults: 0,
            childs: 0,
            ages: [],
            treatment: null,
          },
        ],
      },
      loading: false,
      today: new Date(),
      privacy_url: process.env.VUE_APP_PRIVACY_LINK,
    };
  },
  methods: {
    addOrRemoveRoom($event) {
      if ($event > this.request.rooms.length) {
        this.request.rooms.push({
          adults: 0,
          childs: 0,
          ages: [],
          treatment: null,
        });
      } else {
        this.request.rooms.pop();
      }
    },
    submit() {
      this.$v.request.$touch();
      if (this.$v.request.$anyError) {
        return;
      }
      this.loading = true;
      const request_data = {
        arrival: moment(this.range.start).format("YYYY-MM-DD"),
        departure: moment(this.range.end).format("YYYY-MM-DD"),
        visitor: {
          firstname: this.request.name,
          lastname: "",
          language: 1,
          email: this.request.email,
        },
        rooms: this.request.rooms,
        source: {
          name: "Booking",
        },
      };
      this.$http
        .post("/requests/quote/", request_data)
        .then((response) => {
          this.$router.push({
            name: "quote",
            params: {
              quotes: response.data.quotes,
              request: response.data.request,
            },
          });
        })
        .catch(() => {
          this.$router.push({
            name: "quote",
            params: {
              quotes: [],
              request: [],
            },
          });
        })
        .then(() => {
          // always executed
          this.loading = false;
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.request[name];
      return $dirty ? !$error : null;
    },
  },
  validations() {
    return {
      range: {
        end: {
          notSameAs: not(sameAs(this.range.start)),
        },
      },
      request: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
        rooms: {
          required,
          $each: {
            treatment: {
              required,
            },
            adults: {
              min: minValue(1),
            },
            ages: {
              $each: {
                value: {
                  required,
                },
              },
            },
          },
        },
        privacy: {
          accepted: (val) => val === true,
        },
      },
    };
  },
};
</script>
