<template>
  <div class="col-12 box-booking">
    <div class="custom-control custom-radio">
      <input
        type="radio"
        :id="'radio' + quote_index + index"
        :name="'radio' + quote_index"
        class="custom-control-input"
        :value="room.block_id"
        v-model="confirmed_blocks[quote_index]"
      />
      <label class="custom-control-label" :for="'radio' + quote_index + index"
        ><span class="text-warning blocked mr-md-2">€ {{ room.discounted_price }}</span
        ><span class="text-website mr-2">{{ room.title }}</span></label
      >
      <a
        class="view-details blocked mt-1 float-md-right"
        data-toggle="collapse"
        :href="'#room-' + room.block_id"
        role="button"
        aria-expanded="false"
        :aria-controls="'room-' + room.block_id"
        v-on:click="changeCollapseLabel"
      >
        {{ collapseLabel }}<i class="fas fa-angle-down ml-2"></i>
      </a>
    </div>
    <div class="collapse show" :id="'room-' + room.block_id">
      <div class="card card-body border-0 p-0 pt-3 pt-lg-4">
        <div class="row">
          <div class="col-md-4" v-show="room.image">
            <img :src="room.image" alt="" class="img-fluid mb-3" />
          </div>
          <div
            v-bind:class="{ 'col-md-12': !room.image, 'col-md-8': room.image }"
            class="pb-3"
            v-html="room.description"
          ></div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "RoomQuoteBox",
  props: ["room", "quote_index", "index", "confirmed_blocks"],
  data: function () {
    return {
      collapseLabel: "MENO DETTAGLI",
    };
  },
  methods: {
    changeCollapseLabel() {
      console.log("entrato");
      if (this.collapseLabel == "VEDI DETTAGLI") {
        this.collapseLabel = "MENO DETTAGLI"
      } else {
        this.collapseLabel = "VEDI DETTAGLI"
      }
    },
  },
};
</script>

