<template>
  <div>
    <b-form v-on:submit.prevent v-if="haveAvailableQuotes">
      <div class="overlay" v-show="loading">
        <div class="overlay__inner">
          <div class="overlay__content text-center">
            <span class="spinner"></span>
            <div class="d-block">
              <h5 class="mt-3 mb-0 text-dark">
                Sto confermando il tuo preventivo,<br />solo pochi secondi ...
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="rp-card rp-card-prev mb-3 pt-3 pb-3 courtesy">
            <div class="row">
              <div class="col-12">
                <div class="text-website-bis text-shadow" v-html="introduction_text">
                </div>
              </div>
            </div>
          </div>
        </div>

        <quote-box
          v-for="(quote, index) in quotes"
          v-bind:key="index"
          :index="index"
          :quote="quote"
          :request="request"
          :confirmed_blocks="confirmed_blocks"
          :terms_and_condition_text="terms_and_condition_text"
        />
      </div>
      <div class="rp-card rp-card-prev bg-transparent pt-0 mb-0 pb-0">
        <div class="row">
          <div class="col-md-12 text-center">
            <b-button
              type="submit"
              variant="action"
              class="btn-action-conferma btn-info btn-lg rounded-pill mb-3 d-inline-block"
              @click="showModal"
              :disabled="loading"
            >
              <font-awesome-icon :icon="['fas', 'check']" class="mr-2" />INVIA
              CONFERMA</b-button
            >
            <div class="small text-white">
              <!--  <font-awesome-icon
                :icon="['fas', 'exclamation-circle']"
                class="mr-1"
              />
              Cliccando "conferma" si invia una richiesta di prenotazione che
              sarà confermata definitivamente dalla struttura entro 24 ore dalla
              ricezione. -->
              <p class="mt-2">
                <a
                  href="#"
                  class="btn btn-link text-white btn-sm small"
                  @click="reset_quote"
                >
                  <font-awesome-icon
                    :icon="['fas', 'redo-alt']"
                    class="mr-2"
                  />Desidero rifare il preventivo</a
                >
              </p>
            </div>
            <hr class="dotted" />
          </div>
          <div class="col-md-12" v-show="showAlternativeQuoteOptions">
            <h5 class="text-white text-center mb-4">
              Desideri valutare un preventivo alternativo?
            </h5>
            <div class="row row-flex mb-4" v-show="showHotel('capitol')">
              <div class="col-md-4 pr-md-0">
                <div class="bg-hotel bg-capitol py-7 py-md-5"></div>
              </div>
              <div class="col-md-8 pl-md-0">
                <div class="bg-white box-hotel p-3 pl-md-4">
                  <h6 class="mb-2">
                    <strong>Hotel Capitol</strong>
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert ml-2"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                  </h6>
                  <p>
                    Perché trovi piscina, idromassaggio e angolo fitness,
                    direttamente sulla spiaggia.
                  </p>
                  <a
                    href="https://www.romagnatua.it/hotel-capitol-gatteomare/"
                    target="_blank"
                    class="btn btn-info btn-sm text-white rounded-pill mt-1 d-inline-block"
                    >Scopri l'Hotel Capitol</a
                  >
                </div>
              </div>
            </div>

            <div class="row row-flex mb-4" v-show="showHotel('spiaggia')">
              <div class="col-md-4 pr-md-0">
                <div class="bg-hotel bg-spiaggia py-7 py-md-5"></div>
              </div>
              <div class="col-md-8 pl-md-0">
                <div class="bg-white box-hotel p-3 pl-md-4">
                  <h6 class="mb-2">
                    <strong>Hotel Spiaggia</strong>
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert ml-2"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                  </h6>
                  <p>
                    Perché sei direttamente sulla spiaggia, e trovi caffetteria
                    e soft drinks sempre compresi.
                  </p>
                  <a
                    href="https://www.romagnatua.it/hotel-spiaggia-gatteomare/"
                    target="_blank"
                    class="btn btn-info btn-sm text-white rounded-pill mt-1 d-inline-block"
                    >Scopri l'Hotel Spiaggia</a
                  >
                </div>
              </div>
            </div>

            <div class="row row-flex mb-4" v-show="showHotel('pironi')">
              <div class="col-md-4 pr-md-0">
                <div class="bg-hotel bg-pironi py-7 py-md-5"></div>
              </div>
              <div class="col-md-8 pl-md-0">
                <div class="bg-white box-hotel p-3 pl-md-4">
                  <h6 class="mb-2">
                    <strong>Hotel Pironi</strong>
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert ml-2"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                  </h6>
                  <p>Perché è la nostra proposta budget-friendly.</p>
                  <a
                    href="https://www.romagnatua.it/hotel-pironi-san-mauro-mare/"
                    target="_blank"
                    class="btn btn-info btn-sm text-white rounded-pill mt-1 d-inline-block"
                    >Scopri l'Hotel Pironi</a
                  >
                </div>
              </div>
            </div>

            <div class="row row-flex mb-4" v-show="showHotel('royal')">
              <div class="col-md-4 pr-md-0">
                <div class="bg-hotel bg-royal py-7 py-md-5"></div>
              </div>
              <div class="col-md-8 pl-md-0">
                <div class="bg-white box-hotel p-3 pl-md-4">
                  <h6 class="mb-2">
                    <strong>Hotel Royal</strong>
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert ml-2"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                  </h6>
                  <p>
                    Perchè puoi pranzare in spiaggia e, nel bar dell'hotel,
                    trovi caffetteria e soft-drinks sempre compresi.
                  </p>
                  <a
                    href="https://www.romagnatua.it/hotel-royal-cesenatico/"
                    target="_blank"
                    class="btn btn-info btn-sm text-white rounded-pill mt-1 d-inline-block"
                    >Scopri l'Hotel Royal</a
                  >
                </div>
              </div>
            </div>

            <div class="row row-flex mb-4" v-show="showHotel('albadoro')">
              <div class="col-md-4 pr-md-0">
                <div class="bg-hotel bg-albadoro py-7 py-md-5"></div>
              </div>
              <div class="col-md-8 pl-md-0">
                <div class="bg-white box-hotel p-3 pl-md-4">
                  <h6 class="mb-2">
                    <strong>Hotel Alba d'Oro</strong>
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert ml-2"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      class="text-alert"
                    />
                  </h6>
                  <p>
                    Perché hai tre piscine diverse a disposizione...sempre al
                    sole.
                  </p>
                  <a
                    href="https://www.romagnatua.it/hotel-albadoro-gatteomare/"
                    target="_blank"
                    class="btn btn-info btn-sm text-white rounded-pill mt-1 d-inline-block"
                    >Scopri l'Hotel Alba d'Oro</a
                  >
                </div>
              </div>
            </div>

            <hr class="dotted" />
          </div>
        </div>
      </div>
    </b-form>

    <b-modal
      centered
      ref="confirm-modal"
      hide-footer
      v-if="haveAvailableQuotes"
    >
      <div class="d-block text-center mb-3">
        <font-awesome-icon
          :icon="['fas', 'bell']"
          class="mb-3 fa-5x text-warning"
        />
        <p>
          Cliccando "confermo la prenotazione" si invia una richiesta di
          prenotazione che sarà confermata definitivamente dalla struttura entro
          24 ore dalla ricezione.
        </p>
      </div>

      <b-button
        variant="info"
        class="btn-action-conferma btn-action mt-2"
        block
        @click="submit_confirm"
      >
        <font-awesome-icon :icon="['fas', 'check']" class="mr-2" />CONFERMO LA
        PRENOTAZIONE</b-button
      >

      <b-button class="mt-3" variant="link" block @click="hideModal"
        ><u>No, voglio pensarci ancora</u></b-button
      >
    </b-modal>
    <div class="rp-card rp-card-prev mb-4" v-if="!haveAvailableQuotes">
      <div class="row">
        <div class="col-12 py-3 text-center">
          <font-awesome-icon :icon="['fas', 'sad-tear']" class="mb-3 fa-5x" />
          <p class="mb-3" v-html="no_quote_text">
          </p>
          <b-link
            class="m-2 mr-md-2 btn btn-outline-dark btn-25"
            :href="'https://wa.me/39' + phone + '?text=' + whatsapp_text"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-1" />
            Scrivici su Whatsapp</b-link
          >
          <b-link
            class="m-2 btn btn-outline-dark btn-25"
            :href="
              'mailto:' + email + '?subject='+more_info_email_subject
            "
            target="_blank"
          >
            <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1" />
            Inviaci un' e-mail</b-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteBox from "../components/QuoteBox";

export default {
  name: "Quote",
  components: {
    QuoteBox,
  },
  props: ["request", "quotes"],
  data() {
    return {
      // request: {
      //   id: 36760,
      //   arrival: "2018-06-15",
      //   departure: "2018-06-18",
      // },
      // quotes: [
      //   {
      //     rooms: [
      //       {
      //         title: "CAMERA CLASSIC CLIMA",
      //         description:
      //           "<strong>CAMERA CLASSIC CLIMA - Residenza</strong>\r\n<p><strong>DOTAZIONI</strong>:&nbsp;&bull;Balcone &bull;Servizi privati con box doccia&nbsp;&bull;Cassaforte&nbsp;&bull;Telefono&nbsp;&bull;TV color sat LCD&nbsp;&bull;Aria condizionata&nbsp;&bull;Phon&nbsp;&bull;Wifi</p>\r\n\r\n<p><strong>SERVIZI:</strong>&nbsp;&bull;1 ombrellone e 2 lettini in spiaggia&nbsp;&bull;Bevande ai pasti&nbsp;&bull;Utilizzo 2 teli mare con cambio giornaliero</p>\r\n\r\n<p><em>*Tassa di soggiorno non inclusa. La tassa, sar&agrave; da pagare in contanti direttamente in struttura.</em></p>",
      //         price: "392.00",
      //         image: "http://127.0.0.1:8004/media/rooms/92262_formatofoto.jpg",
      //         block_id: 73723,
      //       },
      //       {
      //         title: "CAMERA CLASSIC CLIMA",
      //         description:
      //           "<strong>CAMERA CLASSIC CLIMA - Residenza</strong>\r\n<p><strong>DOTAZIONI</strong>:&nbsp;&bull;Balcone &bull;Servizi privati con box doccia&nbsp;&bull;Cassaforte&nbsp;&bull;Telefono&nbsp;&bull;TV color sat LCD&nbsp;&bull;Aria condizionata&nbsp;&bull;Phon&nbsp;&bull;Wifi</p>\r\n\r\n<p><strong>SERVIZI:</strong>&nbsp;&bull;1 ombrellone e 2 lettini in spiaggia&nbsp;&bull;Bevande ai pasti&nbsp;&bull;Utilizzo 2 teli mare con cambio giornaliero</p>\r\n\r\n<p><em>*Tassa di soggiorno non inclusa. La tassa, sar&agrave; da pagare in contanti direttamente in struttura.</em></p>",
      //         price: "392.00",
      //         image: "http://127.0.0.1:8004/media/rooms/92262_formatofoto.jpg",
      //         block_id: 73725,
      //       },
      //     ],
      //     adults: 1,
      //     childs: 1,
      //     treatment: "Pensione Completa (Formula tutto compreso)",
      //   },
      //   {
      //     rooms: [
      //       {
      //         title: "CAMERA CLASSIC CLIMA",
      //         description:
      //           "<strong>CAMERA CLASSIC CLIMA - Residenza</strong>\r\n<p><strong>DOTAZIONI</strong>:&nbsp;&bull;Balcone &bull;Servizi privati con box doccia&nbsp;&bull;Cassaforte&nbsp;&bull;Telefono&nbsp;&bull;TV color sat LCD&nbsp;&bull;Aria condizionata&nbsp;&bull;Phon&nbsp;&bull;Wifi</p>\r\n\r\n<p><strong>SERVIZI:</strong>&nbsp;&bull;1 ombrellone e 2 lettini in spiaggia&nbsp;&bull;Bevande ai pasti&nbsp;&bull;Utilizzo 2 teli mare con cambio giornaliero</p>\r\n\r\n<p><em>*Tassa di soggiorno non inclusa. La tassa, sar&agrave; da pagare in contanti direttamente in struttura.</em></p>",
      //         price: "392.00",
      //         image: "http://127.0.0.1:8004/media/rooms/92262_formatofoto.jpg",
      //         block_id: 73724,
      //       },
      //       {
      //         title: "CAMERA CLASSIC CLIMA",
      //         description:
      //           "<strong>CAMERA CLASSIC CLIMA - Residenza</strong>\r\n<p><strong>DOTAZIONI</strong>:&nbsp;&bull;Balcone &bull;Servizi privati con box doccia&nbsp;&bull;Cassaforte&nbsp;&bull;Telefono&nbsp;&bull;TV color sat LCD&nbsp;&bull;Aria condizionata&nbsp;&bull;Phon&nbsp;&bull;Wifi</p>\r\n\r\n<p><strong>SERVIZI:</strong>&nbsp;&bull;1 ombrellone e 2 lettini in spiaggia&nbsp;&bull;Bevande ai pasti&nbsp;&bull;Utilizzo 2 teli mare con cambio giornaliero</p>\r\n\r\n<p><em>*Tassa di soggiorno non inclusa. La tassa, sar&agrave; da pagare in contanti direttamente in struttura.</em></p>",
      //         price: "392.00",
      //         image: "http://127.0.0.1:8004/media/rooms/92262_formatofoto.jpg",
      //         block_id: 73726,
      //       },
      //     ],
      //     adults: 1,
      //     childs: 1,
      //     treatment: "Pensione Completa (Formula tutto compreso)",
      //   },
      // ],
      confirmed_blocks: [],
      loading: false,
      phone: process.env.VUE_APP_PHONE,
      email: process.env.VUE_APP_EMAIL,
      mode: process.env.VUE_APP_MODE,
      whatsapp_text: "",
      introduction_text: "",
      terms_and_condition_text:"",
      no_quote_text:"",
      more_info_email_subject:""
    };
  },
  computed: {
    canSubmit: function () {
      return this.quotes.length === this.confirmed_blocks.length;
    },
    haveAvailableQuotes: function () {
      return this.quotes.length > 0 && this.request;
    },
    showAlternativeQuoteOptions: function () {
      return !(this.mode == "riz" || this.mode == "giulietta" || this.mode == "atlantica");
    },
  },
  mounted() {
    this.quotes.forEach((x) => this.confirmed_blocks.push(x.rooms[0].block_id));
    this.$http
      .get("/booking/config")
      .then((response) => {
        this.whatsapp_text = response.data.whatsapp_text;
        this.introduction_text = response.data.introduction_text;
        this.terms_and_condition_text = response.data.terms_and_condition_text;
        this.more_info_email_subject = response.data.more_info_email_subject;
        this.no_quote_text = response.data.no_quote_text;
      })
      .catch((error) => {
        console.log(error);
        this.error = true;
      });
  },
  methods: {
    reset_quote() {
      this.$router.push({ name: "home" });
    },
    showModal() {
      this.$refs["confirm-modal"].show();
    },
    hideModal() {
      this.$refs["confirm-modal"].hide();
    },
    submit_confirm() {
      if (!this.canSubmit) {
        return;
      }
      this.hideModal();
      this.loading = true;
      let confirm_data = {
        request_id: this.request.id,
        block_ids: this.confirmed_blocks,
      };

      this.$http
        .patch("/requests/confirm/", confirm_data)
        .then(() => {
          // always executed
          this.loading = false;
          this.$router.push({
            name: "confirm",
            params: {
              id: this.request.id,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          this.loading = false;
        });
    },
    showHotel: function (name) {
      switch (name) {
        case "albadoro":
          if (this.mode == "royal" || this.mode == "pironi") {
            return true;
          }
          break;
        case "spiaggia":
          if (
            this.mode == "royal" ||
            this.mode == "pironi" ||
            this.mode == "capitol" ||
            this.mode == "royal"
          ) {
            return true;
          }
          break;
        case "royal":
          if (this.mode == "albadoro" || this.mode == "spiaggia") {
            return true;
          }
          break;
        case "pironi":
          if (
            this.mode == "albadoro" ||
            this.mode == "capitol" ||
            this.mode == "spiaggia"
          ) {
            return true;
          }
          break;
        case "capitol":
          break;
      }
      return false;
    },
  },
};
</script>