<template>
  <div class="col-12 form-margin">
    <div class="w-100">
      <hr class="mt-1" />
      <h5 class="font-weight-bolder mb-3">
        <font-awesome-icon :icon="['fas', 'key']" class="mr-2" />Camera {{
          index + 1
        }}
      </h5>
    </div>
    <div class="w-100 form-margin">
      <label>N. adulti</label>
      <b-form-spinbutton
        id="adults"
        v-model="v.adults.$model"
        min="0"
        max="5"
        class="pr-0 pl-0"
        :state="validateState('adults')"
        aria-describedby="adults-feedback"
      ></b-form-spinbutton>
      <b-form-invalid-feedback id="adults-feedback"
        >Il numero di adulti non può essere 0</b-form-invalid-feedback
      >
    </div>
    <div class="w-100">
      <label>N. bambini</label>
      <b-form-spinbutton
        id="childs"
        v-model="room.childs"
        min="0"
        max="6"
        class="form-margin pr-0 pl-0"
        @input="addOrRemoveAge"
      ></b-form-spinbutton>
      <div class="row">
        <Age
          v-for="(age, index) in room.ages"
          :key="index"
          :index="index"
          :v="v.ages.$each[index]"
        />
      </div>
    </div>
    <div class="w-100">
      <label>Trattamento</label>
      <b-form-select
        name="treatment"
        v-model="v.treatment.$model"
        :options="treatments"
        aria-describedby="email-feedback"
        :state="validateState('treatment')"
      ></b-form-select>
      <b-form-invalid-feedback id="email-feedback"
        >Questo campo non può essere vuoto</b-form-invalid-feedback
      >
    </div>
  </div>
</template>

<script>
import Age from "../components/Age";
export default {
  name: "Room",
  props: ["index", "room", "v"],
  components: {
    Age,
  },
  data: function () {
    return {
      treatments: [],
      error: "",
    };
  },
  methods: {
    addOrRemoveAge($event) {
      if ($event > this.room.ages.length) {
        this.room.ages.push({value:""});
      } else {
        this.room.ages.pop();
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.v[name];
      return $dirty ? !$error : null;
    },
  },
  mounted() {
    this.$http
      .get("/treatments/")
      .then((response) => {
        this.treatments = response.data.map((treatment) => {
          var dict = { value: treatment.id, text: treatment.name };
          return dict;
        });
      })
      .catch((error) => {
        console.log(error);
        this.error = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>