<template>
  <div id="app">
    <header>
      <div class="container text-center mt-4">
        <div class="row">
          <div class="col-12">
            <img :src="logo" class="logo img-fluid mb-3" />
            <div class="text-uppercase text-website-bis local text-shadow mb-3">
              {{ administrative_area }}
            </div>
          </div>
        </div>
      </div>
    </header>
    <main>
      <div class="container">
        <router-view></router-view>
      </div>
    </main>
    <footer>
      <div class="container text-center mb-5">
        <p class="small text-website-bis" v-html="address"></p>
      </div>
      <div class="fixed-bottom chat">
        <a
          :href="'https://wa.me/39' + phone + '?text=' + whatsapp_text"
          target="_blank"
        >
          <img src="@/assets/img/wa.png" class="img-fluid bump" />
        </a>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      address: process.env.VUE_APP_ADDRESS,
      administrative_area: process.env.VUE_APP_ADMINISTRATIVE_AREA,
      phone: process.env.VUE_APP_PHONE,
      folder: process.env.VUE_APP_STATIC_FOLDER,
      base_url: process.env.BASE_URL,
      whatsapp_text: "",
    };
  },
  computed: {
    logo: function () {
      return this.base_url + this.folder + "/logo.png";
    },
  },
  mounted() {
    this.$http
      .get("/booking/config")
      .then((response) => {
        this.whatsapp_text = response.data.whatsapp_text;
      })
      .catch((error) => {
        console.log(error);
        this.error = true;
      });
  },
};
</script>