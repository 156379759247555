import Vue from 'vue'
import axios from "axios";

axios.interceptors.request.use(function(axiosConfig) {
    axiosConfig.headers = {
        'Content-Type': 'application/json',
        'Accept-Language': 'it-IT',
        'User-Agent': process.env.VUE_APP_USER_AGENT
    };
    axiosConfig.timeout = 10000;
    axiosConfig.params = {
        'userAgent': encodeURIComponent(process.env.VUE_APP_USER_AGENT),
    };
    axiosConfig.baseURL = process.env.VUE_APP_API_DOMAIN
    return axiosConfig;
});

Vue.prototype.$http = axios