<template>
  <div class="col-6 form-margin">
    <label>Età bimbo (anni)</label>
    <b-form-select
      name="value"
      v-model="v.value.$model"
      :options="options"
      aria-describedby="email-feedback"
      :state="validateState('value')"
    ></b-form-select>
    <b-form-invalid-feedback id="email-feedback"
      >Questo campo non può essere vuoto</b-form-invalid-feedback
    >
  </div>
</template>

<script>
var options = Array.from(Array(16).keys());
export default {
  name: "Age",
  props: ["v"],
  data() {
    return {
      options: options,
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.v[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>