import Home from "./pages/Home";
import Quote from "./pages/Quote";
import Confirm from "./pages/Confirm";
import NotFound from "./pages/NotFound";

export default [{
        name: 'home',
        path: '/',
        component: Home,
    },
    {
        name: 'quote',
        path: '/quote',
        component: Quote,
        props: true
    },
    {
        name: 'confirm',
        path: '/confirm/:id',
        component: Confirm,
        props: true
    },
    {
        name: 'not-found',
        path: '*',
        component: NotFound,
    }
];