<template>
  <div class="col-md-12">
    <div class="rp-card rp-card-prev mb-4">
      <div class="row">
        <div class="col-12 py-7 text-center">
          <h3 class="font-weight-bolder mb-0 text-center">
            <font-awesome-icon
                :icon="['fas', 'ban']"
                class="mb-3 fa-4x d-inline-block"
              /><br>
           ERROR 404<br>
           Sorry, page not found.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
};
</script>