<template>
  <div class="col-md-12">
    <div class="rp-card rp-card-prev mb-4">
      <div class="row">
        <div class="col-12">
          <h5 class="font-weight-bolder mb-0">
            <font-awesome-icon :icon="['fas', 'check']" class="mr-2" />
            Il Preventivo #{{ id }} è stato preso in carico.
          </h5>
          <hr class="" />
          <p>
            Gentile cliente, <strong>abbiamo ricevuto la sua richiesta</strong>,
            sarà confermata definitivamente dalla struttura entro 24 ore.
          </p>
          <p>
            A breve riceverà una email con la
            <strong>richiesta della caparra</strong> da versare.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  props: ["id"],
  data() {
    return {
      conversion_event: process.env.VUE_APP_GOOGLE_ADWORDS_CONVERSION_CONFIRM,
    };
  },

  mounted() {
    if (this.conversion_event) {
      this.$gtag.event("conversion", {
        send_to: this.conversion_event,
      });
    }
  },
};
</script>