import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import VueGtag from "vue-gtag";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { faCalculator, faUser, faCalendarAlt, faUserFriends, faConciergeBell, faCheck, faExclamationCircle, faRedoAlt, faKey, faBan, faBell, faSadTear, faEnvelope, faStar, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
if (process.env.VUE_APP_MODE == "riz" || process.env.VUE_APP_MODE == "giulietta") {
    require('./assets/scss/main-riz.scss')
} else if (process.env.VUE_APP_MODE == "atlantica") {
    require('./assets/scss/main-atlantica.scss')
} else {
    require('./assets/scss/main.scss')
}

import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import Vuelidate from 'vuelidate'
import routes from './ruotes';
import './filters.js';
import './axios.conf'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Vuelidate);
Vue.use(VueRouter);

library.add(faCalculator, faUser, faCalendarAlt, faUserFriends, faConciergeBell, faCheck, faExclamationCircle, faRedoAlt, faKey, faBan, faBell, faSadTear, faEnvelope, faWhatsapp, faStar, faFileInvoice)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

Vue.config.productionTip = false

//Routing
const router = new VueRouter({
    routes, // short for `routes: routes`
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

if (process.env.VUE_APP_GOOGLE_ANALYTICS && process.env.VUE_APP_GOOGLE_ADWORDS) {
    Vue.use(VueGtag, {
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
        includes: [
            { id: process.env.VUE_APP_GOOGLE_ADWORDS },
        ]
    }, router);
}


new Vue({
    render: h => h(App),
    router,
}).$mount('#app')